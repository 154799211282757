import { request } from '../index.js'

// 设备统计概况
export const sbgkGet = function(url) {
    return request({
        method: 'GET',
        url: '/aps' + url,
        headers: { Authorization: window.localStorage.getItem('user') },
    })
}

// 违规图片：按年度统计违规类型分组统计
export const illegalPicture = function(url, year) {
    return request({
        method: 'GET',
        url: '/aps' + url + `/${year}`,
        headers: { Authorization: window.localStorage.getItem('user') },
    })
}

// 违规图片：按类型获取图片列表接口
export const illegalPictureList = function(url, year, wglx) {
    return request({
        method: 'GET',
        url: '/aps' + url + `?year=${year}&wglx=${wglx}`,
        headers: { Authorization: window.localStorage.getItem('user') },
    })
}

// 违规抓拍统计
export const violationCaptureStatistics = function(url) {
    return request({
        method: 'GET',
        url: '/aps' + url,
        headers: { Authorization: window.localStorage.getItem('user') },
    })
}

// 飞行记录
export const flightRecord = function(url) {
    return request({
        method: 'GET',
        url: '/aps' + url,
        headers: { Authorization: window.localStorage.getItem('user') },
    })
}

// 违规处理
export const violationHandling = function(url) {
    return request({
        method: 'GET',
        url: '/aps' + url,
        headers: { Authorization: window.localStorage.getItem('user') },
    })
}

// 无人机信息
export const droneInformation = function(url) {
    return request({
        method: 'GET',
        url: '/aps' + url,
        headers: { Authorization: window.localStorage.getItem('user') },
    })
}