<template>
    <swiper ref="mySwiper" :options="swiperOption" style="width:100%; height:100%">
        <swiper-slide v-for="(item, index) in wglxtupians" :key="index">
            <img class="carousel-img" :src="item.avatar" alt="" />
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev" style="color: #fff;"></div>
        <div class="swiper-button-next" slot="button-next" style="color: #fff;"></div>
    </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  name: "MySwiper",
  props: {
    wglxtupians: {
        type: Array,
        default: []
    }
  },
  data() {
    return {
        images: [],
        swiperOption: {
            // 衔接滑动
            loop: true,
            // 自动滑动
            autoplay: {
                delay: 2000,
                stopOnLastSlide: false,
                    disableOnInteraction: false,
            },
            slidesPerView: 1,
            paceBetween: 20,
            slideToClickedSlide: true,
            // 使用前进后退按钮来控制Swiper切换。
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                },
            pagination: {
                el: '.swiper-pagination', //与slot="pagination"处 class 一致
                clickable: true,
                type: 'fraction',
            },
        },
    };
  },
components: {
    Swiper,
    SwiperSlide,
},
  mounted() {},
};
</script>
<style scoped>
.carousel-img {
  width: 100%;
  height: 100%;
}

.swiper-container {
  height: 350px;
  width: 95%;
}
</style>