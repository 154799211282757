<template>
    <div id="mapContainer" style="width:100%; height:100%; position: absolute;"></div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
    name: 'MapComponent',
    props: {
        dthzsllds: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            btndisabled: true,
            loadshow: false,
            loadtext: '提交中',
            showtop: true,
            starshow: true,
            endshow: true,
            userinfo: {},
            cphList: [],
            sjmcList: [],
            cphid: '',
            cphmc: '',
            sjmcid: '',
            sjmc: '',
            startime: '',
            endtime: '',
            sigInfo: {},
            map: null,
            lineArr: [],
            lineArrlast: [],
            lineArrPre: [],
            marker: '',
            k: 0,
        }
    },
    mounted() {
        AMapLoader.reset()
        this.getmap()
    },
    methods: {
        getmap() {
            AMapLoader.load({
                // 替换为你的高德地图API Key
                key: 'ec9cb54feced4a6f72021702ee8ca907',
                // version: '2.0',      
                plugins: ['AMap.MoveAnimation','AMap.Geolocation'],
            }).then((AMap) => {
                this.lineArr = []
                this.lineArr.push(this.dthzsllds)
                let self = this;
                let polylineX,passedPolyline,nColorLength,currentLen,latlonarr,pointList
                let colors = ["#5ecbd2",  "#329262", "#5574a6", "#3b3eac"];
                this.map = new AMap.Map("mapContainer", {
                  layers: [
                    // 卫星
                    //new AMap.TileLayer.Satellite(),
                    // 路网
                    //new AMap.TileLayer.RoadNet()
                  ],
                  zoom:16,
                  center: [119.685439,31.338547],
                });
                // 开启深色模式
                this.map.setMapStyle('amap://styles/grey');
                // 移除所有的多边形线条
                if (polylineX) {
                    polylineX.setMap(null);
                    polylineX = null;
                }
                // 移除移动轨迹的多边形线条
                if (passedPolyline) {
                    passedPolyline.setMap(null);
                    passedPolyline = null;
                }
                // 移除标记
                if (this.marker) {
                    this.marker.setMap(null);
                    this.marker = null;
                }
                // 清空相关数组
                this.lineArrPre = [];
                this.lineArrlast = [];
                if(this.lineArr.length > 0) {
                    for(let j = 0; j < this.lineArr.length; j++) {
                        polylineX = "polyline" + j
                        //计算取颜色的函数
                        nColorLength = colors.length;
                        currentLen = 0;
                        if (j > nColorLength) {
                            currentLen = j % 14;
                        }
                        else {
                            currentLen = j;
                        }
                        latlonarr = this.lineArr[j].split('|')
                        if(latlonarr.length > 0) {
                            if(j < this.lineArr.length - 1) {
                                this.lineArrPre = []
                                for(let i = 0; i < latlonarr.length; i++) {
                                    pointList = latlonarr[i].split(',');
                                    if (pointList.length > 0) {
                                        this.lineArrPre.push(new AMap.LngLat(pointList[1], pointList[0]));
                                    }
                                }
                                polylineX = new AMap.Polyline({
                                    map: this.map,
                                    path: this.lineArrPre,
                                    showDir: true,
                                    strokeColor: colors[currentLen],  //线颜色
                                    strokeWeight: 5,     //线宽
                                });
                            } else {    //最后一条轨迹绘制移动轨迹
                                for(let i = 0; i < latlonarr.length; i++) {
                                    pointList = latlonarr[i].split(',');
                                    if (pointList.length > 0) {
                                        this.lineArrlast.push(new AMap.LngLat(pointList[1], pointList[0]));
                                    }
                                }
                                polylineX = new AMap.Polyline({
                                    map: this.map,
                                    path: this.lineArrlast,
                                    showDir: true,
                                    strokeColor: colors[currentLen],  //线颜色
                                    strokeWeight: 5,    //线宽
                                  strokeStyle: "dashed" , //线样式
                                  strokeDasharray: [15, 15],
                                });
                                if(this.lineArrlast.length > 0) {
                                    this.marker = new AMap.Marker({
                                        map: this.map,
                                        position: [this.lineArrlast[0].lng,this.lineArrlast[0].lat],
                                        icon: "http://public.half-half.cn/icon/wrj.png",
                                        offset: new AMap.Pixel(-26, -13),
                                        autoRotation: true,
                                        angle:45,
                                      zIndex:99,
                                    });

                                }
                              AMap.moveCamera(CameraUpdateFactory.zoomTo(16));
                                passedPolyline = new AMap.Polyline({
                                    map: this.map,
                                    // path: lineArr,
                                    strokeColor: "#AF5", //线颜色
                                    strokeOpacity: 1, //线透明度
                                    strokeWeight: 5 ,//线宽

                                });
                                this.marker.on('moving', function (e) {
                                    passedPolyline.setPath(e.passedPath);
                                });
                            }
                        }
                    }
                }
                this.map.setFitView();
            })
        },
        // clearOldRoutes() {
        //     // 移除所有的多边形线条
        //     if (this.polylineX) {
        //         this.polylineX.setMap(null);
        //         this.polylineX = null;
        //     }
            
        //     // 移除移动轨迹的多边形线条
        //     if (this.passedPolyline) {
        //         this.passedPolyline.setMap(null);
        //         this.passedPolyline = null;
        //     }
            
        //     // 移除标记
        //     if (this.marker) {
        //         this.marker.setMap(null);
        //         this.marker = null;
        //     }

        //     // 清空相关数组
        //     this.lineArrPre = [];
        //     this.lineArrlast = [];
        // },
        // 开始
        starmove() {
            this.starshow = false
            this.btndisabled = false
            this.marker.moveAlong(this.lineArrlast, 500);
        },
        // 暂停
        endmove() {
            this.endshow = false
            this.marker.pauseMove();
        },
        // 继续
        resumeAnimation() {
            this.endshow = true
            this.marker.resumeMove();
        },
        // 停止
        stopAnimation() {
            this.starshow = true
            this.endshow = true
            this.btndisabled = true
            this.marker.stopMove();
        },
    }
};
</script>