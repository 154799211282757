<template>
    <div id="ruralScreen">

        <div class="header">
            <p>无人机智慧巡防大屏</p>
            <div class="date">
                <span>{{week}}</span>
                <span>{{dates}}</span> 
            </div>
            <div class="weather">
                <span>{{text}}</span>
                <span>{{temp}}℃</span>
                <span>{{windDir}}{{windScale}}级</span>
            </div>
            <div class="homepage" @click="toBack()">返回首页</div>

        </div>
        <div class="main">
            <div id="myMap">
              <Map ref="map" :dthzsllds="dthzsllds"/>
            </div>
            <div class="mainBox">
                <div class="lefts" :class="!threeFlag3 ? 'translations' : ''">
                    <div class="leftBoxs">
                        <div class="leftBoxConMain" v-for="(item, index) in leftBoxTable" :key="index" :style="{backgroundImage: `url('${item.backG}')`,backgroundSize: '100% 100%'}">
                            <div class="leftBoxConMain1">
                                <!-- <img class="leftBoxs-img" :src="item.backG"/> -->
                            </div>
                            <div class="leftBoxs-main">
                                <div class="num">{{item.num}}</div>
                                <div class="name">{{item.name}}</div>
                            </div>
                        </div>
                        <div class="mapBottom">
                                <button class="btn" id="start"  @click="startAnimation()">开始动画</button>
                                <button class="btn" id="pause"  @click="pauseAnimation()">暂停动画</button>
                                <button class="btn" id="resume"  @click="resumeAnimation()">继续动画</button>
                                <button class="btn" id="stop"  @click="stopAnimation()">停止动画</button>
                        </div>
                    </div>
                </div>
                <div class="rights" :class="!threeFlag3 ? 'translations2' : ''">
                    <div class="rightLxs" style="position: absolute;top: 6%;right: 75%;">
                        <el-select v-model="optionListLXs" placeholder="请选择飞行路线" @change="optionListLXChange">
                            <el-option
                            v-for="item in optionListLX"
                            :key="item.ldmc"
                            :label="item.ldmc"
                            :value="item.ldmc">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="rightBoxs">
                        <div class="distribution">
                            <div class="boxTitile">
                                <span>无人机信息</span>
                            </div>
                            <div class="distributionBox" @click="toPath">
                                <div class="distributionBoxMain">
                                    <div class="distributionBoxMain-left">
                                        <img class="img" :src="require('@/assets/wurenji.jpg')"/>
                                    </div>
                                    <div class="distributionBoxMain-right">
                                        <div class="right-top">
                                            <el-select class="sel" v-model="value" filterable placeholder="请选择">
                                                <el-option
                                                v-for="item in optionList"
                                                :key="item.id"
                                                :label="item.wrjmc"
                                                :value="item.wrjmc"
                                                @click.native="getWrjDetail(item.wrjbh)">
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <div class="right-top sheBei">
                                            <span>设备编号</span>
                                            <span>{{sbbhValue}}</span>
                                        </div>
                                        <div class="right-top sheBei">
                                            <span>设备型号</span>
                                            <span>{{sbxhValue}}</span>
                                        </div>
                                        <div class="right-top sheBei">
                                            <span>负责人</span>
                                            <span>{{fzrValue}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="distributionBoxTitle">
                                    <div class="distributionBoxTitle-top">
                                        <div class="one">
                                            <div>购置日期</div>
                                            <div :style="{backgroundImage: `url('${backgroundImageBackG}')`,backgroundSize: '100% 100%'}">{{num1}}</div>
                                        </div>
                                        <div class="one">    
                                            <div>归属组织</div>
                                            <div :style="{backgroundImage: `url('${backgroundImageBackG}')`,backgroundSize: '100% 100%'}">{{num2}}</div>
                                        </div>
                                        <div class="one">
                                            <div>执行任务次数</div>
                                            <div :style="{backgroundImage: `url('${backgroundImageBackG}')`,backgroundSize: '100% 100%'}">{{num3}}</div>
                                        </div>
                                    </div>
                                    <div class="distributionBoxTitle-bottom">
                                        <div class="two">
                                            <div>飞行总量</div>
                                            <div :style="{backgroundImage: `url('${backgroundImageBackG}')`,backgroundSize: '100% 100%'}">{{num4}}</div>
                                        </div>
                                        <div class="two">
                                            <div>运行总时间</div>
                                            <div :style="{backgroundImage: `url('${backgroundImageBackG}')`,backgroundSize: '100% 100%'}">{{num5}}</div>
                                        </div>
                                        <div class="two">
                                            <div>维护保养次数</div>
                                            <div :style="{backgroundImage: `url('${backgroundImageBackG}')`,backgroundSize: '100% 100%'}">{{num6}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="networks">
                            <div class="boxTitile">
                                <span>违规抓拍统计</span>
                            </div>
                            <div class="netWorksBox">
                                <div ref="myChart" id="myChart" style="width: 100%; height: 100%;"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottoms" :class="!threeFlag3 ? 'translations2' : ''">
            <div class="bottoms-one">
                <div class="boxTitile">
                    <span>飞行记录</span>
                </div>
                <div class="netWorksBox">
                    <template>
                        <el-table
                            ref="tableData_realtime"
                            class="tableStyle"
                            :data="tableData"
                            style="width: 100%"
                            :row-class-name="tableRowClassName">
                            <el-table-column prop="sbmc" align="center" label="设备名称"></el-table-column>
                            <el-table-column prop="sbbh" align="center" label="设备编号"></el-table-column>
                            <el-table-column prop="fxms" align="center" label="飞行模式"></el-table-column>
                            <el-table-column prop="fxlx" align="center" label="飞行路线"></el-table-column>
                            <el-table-column prop="fxlc" align="center" label="飞行里程"></el-table-column>
                            <el-table-column prop="fxsj" align="center" label="飞行时间"></el-table-column>
                            <el-table-column prop="rwrq" align="center" label="任务日期"></el-table-column>
                            <el-table-column prop="zxr" align="center" label="执行人"></el-table-column>
                        </el-table>
                    </template>
                </div>
            </div>
            <div class="bottoms-two">
                <div class="boxTitile">
                    <span>违规处理</span>
                </div>
                <div ref="netWorksBoxs" class="netWorksBox" style="position: relative;">
                    <div class="aaaaa" style="position: absolute">
                        <div class="netWorksBox-main" v-for="(item, index) in netWorksBoxMain" :key="index">
                            <img :src="item.avatar" alt="" style="image-rendering: pixelated;">
                            <div>
                                <div class="main main1">
                                    <span>抓拍时间：</span>
                                    <span>{{item.zpsj}}</span>
                                </div>
                                <div class="main main2">
                                    <span>抓拍设备：</span>
                                    <span>{{item.sbmc}}</span>
                                </div>
                                <div class="main main3">
                                    <span>违规类型：</span>
                                    <span>{{item.wglx}}</span>
                                </div>
                                <div class="main main4">
                                    <span>处理方式：</span>
                                    <span>{{item.clfs}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottoms-three">
                <div class="boxTitile">
                    <span>违规图片</span>
                </div>
                <div class="netWorksBox">
                    <div class="swiper">
                        <MySwiper v-if="wglxtupians.length > 0" :wglxtupians="wglxtupians"></MySwiper>
                        <div class="swiperNone" v-else style="font-size: 16px;position: relative;top: 25%;left: 25%;">暂无图片</div>
                    </div>
                    <div class="contant">
                        <div
                            class="leftBoxConMain"
                            v-for="(item, index) in leftBoxConMain"
                            :key="index"
                            :style="{backgroundImage: `url('${item.backG}')`,backgroundSize: '100% 100%'}"
                            @click="clickWglxTuPians(index)"
                        >
                            <div class="leftBoxConMain1">
                                <!-- <img class="leftBoxs-img" :src="item.backG"/> -->
                            </div>
                            <div class="leftBoxs-main">
                                <div class="num">{{item.num}}</div>
                                <div class="name">{{item.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    illegalPicture,illegalPictureList,
    violationCaptureStatistics,
    flightRecord,
    violationHandling,
    droneInformation,
    sbgkGet
} from '@/request/api/wrj'
import {option} from './violationCaptureStatistics'
import * as echarts from 'echarts'
import Map from './map'
import MySwiper from "./mySwiper.vue";
export default {
    data(){
        return {
            optionListLXs:'',
            optionListLX: [],
            num1: '',
            num2: '',
            num3: '',
            num4: '',
            num5: '',
            num6: '',
            sbbhValue: '',
            sbxhValue: '',
            fzrValue: '',
            dibiao_clear: null,
            isScroll: true,
            markerPosition: [116.397428, 39.90923],
            year: '2024',
            weatherKey: "5a0bc17ca9a643dc84fee66d458770ce",
            dates: '',
            week: '',
            text: '',
            temp: '',
            windDir: '',
            windScale: '',
            threeFlag3: true,
            backgroundImageBackG: require('@/assets/wrj/anniu.png'),
            // 无人机设备选择
            value: '',
            optionList:[],
            netWorksBoxMain: [],
            tableData: [],
            distributionBoxTitleTable2: [
            ],
            // 违规图片
            leftBoxConMain: [
                {
                    backG: require('@/assets/wrj/weiguizhuapai.png'),
                    name: '违建抓拍',
                    num:0
                },
                {
                    backG: require('@/assets/wrj/weiguiduifang.png'),
                    name: '违规堆放',
                    num:0
                },
                {
                    backG: require('@/assets/wrj/reyuan.png'),
                    name: '热源抓拍',
                    num:0
                },
            ],
            // 设备统计概况
            leftBoxTable: [
                {
                    backG: require('@/assets/wrj/wurenjishuliang.png'),
                    name: '无人机数量',
                    num:0
                },
                {
                    backG: require('@/assets/wrj/feixingyuan.png'),
                    name: '飞行员数量',
                    num:0
                },
                {
                    backG: require('@/assets/wrj/renwucishu.png'),
                    name: '任务次数',
                    num:0
                },
                {
                    backG: require('@/assets/wrj/luduanzongshu.png'),
                    name: '路段总数',
                    num:0
                },
            ],
            dthzsllds: '',
            wglxtupianWJZP: [],
            wglxtupianWJDF: [],
            wglxtupianRYZP: [],
            wglxtupians: [],
        }
    },
    components: {
        Map,
        MySwiper
    },
    mounted(){
        this.dates = this.getDay(0);
        this.sbgkGets()
        this.droneInformations()
        this.flightRecords()
        this.requestLookup();
        this.getCurrentWeek();
        this.illegalPictureData()
        this.illegalPictureLists()
        this.captureStatistics()
        // this.initChart()
        this.violationHandl()
        this.swipers()//轮播
        this.getList()
    },
    methods: {
        clickWglxTuPians(index) {
            if (index === 0) {
                this.wglxtupians = this.wglxtupianWJZP
            } else if (index === 1) {
                this.wglxtupians = this.wglxtupianWJDF
            } else {
                this.wglxtupians = this.wglxtupianRYZP
            }
        },
        toBack() {
            window.localStorage.setItem("marking", "5");
            this.$router.push("/backHome");
        },
        sbgkGets() {
            sbgkGet('/dptj07/sbgk').then(res => {
                if (res.data.state === 'success') {
                    //console.log('设备统计概况=====>',res);
                    // 无人机数量
                    this.leftBoxTable[0].num = res.data.data.wrjs
                    // 飞行员数量
                    this.leftBoxTable[1].num = res.data.data.fxyrs
                    // 任务次数
                    this.leftBoxTable[2].num = res.data.data.rwcs
                    // 路段总数
                    this.leftBoxTable[3].num = res.data.data.ldzs
                }
            })
        },

        getList() {
            this.$get("/ldxx/page").then((res) => {
                if (res.data.state == "success") {
                    //console.log("获取路段信息列表===>", res)
                    this.dthzsllds = ''
                    this.optionListLX = []
                    res.data.data.forEach(item => {
                        this.optionListLX.push(item)
                    })
                    this.optionListLXs = this.optionListLX[0].ldmc
                    this.dthzsllds = this.optionListLX[0].dthzslld
                    this.$refs.map.getmap()
                }
            });
        },
        optionListLXChange(row) {
            this.$get("/ldxx/page").then((res) => {
                if (res.data.state == "success") {
                    this.dthzsllds = ''
                    let tableData2 = res.data.data.filter(item => row === item.ldmc)
                    this.dthzsllds = tableData2[0].dthzslld
                    this.$refs.map.getmap()
                }
            });
        },
        swipers() {
            let isScroll = true
            let div = document.getElementsByClassName('el-table__body-wrapper')[0]
            let t = document.getElementsByClassName('el-table__body')[0]
            div.addEventListener('mouseenter', () => {
                isScroll = false
            })
            div.addEventListener('mouseleave', () => {
                isScroll = true
            })
            setInterval(() =>{
                if(isScroll) {
                    let data = this.tableData[0]
                    setTimeout(() => {
                        this.tableData.push(data)
                        t.style.transition = 'all 2.5s'
                        t.style.marginTop = '-210px'
                    }, 500)
                    setTimeout(() =>{
                        this.tableData.splice(0,1)
                        t.style.transition = 'all 0s ease 0s'
                        t.style.marginTop = '0'
                    }, 1000)
                }
            }, 2500)
        },
        // 开始动画
        startAnimation() {
            this.$refs.map.starmove()
        },
        // 暂停动画
        pauseAnimation() {
            this.$refs.map.endmove()
        },
        // 继续动画
        resumeAnimation() {
            this.$refs.map.resumeAnimation()
        },
        // 停止动画
        stopAnimation() {
            this.$refs.map.stopAnimation()
        },
        // 无人机信息
        droneInformations() {
            droneInformation('/wrjgl/getWrjxxs').then(res => {
                if (res.data.state === 'success') {
                    //console.log('res==无人机信息==>',res);
                    this.optionList = res.data.data
                  this.getWrjDetail( this.optionList[0].wrjbh)
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
      // 无人机大屏统计详情
      getWrjDetail(wrjbh) {
        this.$get(`/dptj07/wrjtj/${wrjbh}`,{}) .then(res => {
          if (res.data.state === 'success') {
            //console.log('res==无人机大屏统计==>',res);
            let data=res.data.data;
            this.value = data.wrjmc
            this.sbbhValue = data.wrjbh ? data.wrjbh : ''
            this.sbxhValue = data.wrjlx ? data.wrjlx : ''
            this.fzrValue = data.fzr ? data.fzr : ''
            this.num1 = data.gzrq ? data.gzrq.slice(0,10) : 0
            this.num2 = data.owner ? data.owner : 0
            this.num3 = data.rwcs ? data.rwcs : 0
            this.num4 = data.fxlc ? data.fxlc : 0
            this.num5 = data.fxsj ? data.fxsj : 0
            this.num6 = data.bycs ? data.bycs : 0
          } else {
            this.$message.error(res.data.msg);
          }
        })
      },
        // 违规处理
        violationHandl() {
            violationHandling('/zpjl/page').then(res => {
                if (res.data.state === 'success') {
                    //console.log('res==违规处理==>',res);
                    this.netWorksBoxMain = res.data.data
                    this.netWorksBoxMain.forEach(row => {
                        this.getUrl(row)
                        if (row.wglx === '违建抓拍') {
                            this.wglxtupianWJZP.push(row)
                        } else if (row.wglx === '违规堆放') {
                             this.wglxtupianWJDF.push(row)
                        } else {
                             this.wglxtupianRYZP.push(row)
                        }
                    })
                    this.wglxtupianWJZP.forEach(row => this.getUrl(row))
                    this.wglxtupianWJDF.forEach(row => this.getUrl(row))
                    this.wglxtupianRYZP.forEach(row => this.getUrl(row))
                    this.wglxtupians = this.wglxtupianWJZP
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        getUrl(row) {
            if (row.zptp && row.zptp.length) {
                let baseImg = JSON.parse(row.zptp);
                for (let a = 0; a < baseImg.length; a++) {
                    this.$getBlob("/fileops/show", {
                        fileName: baseImg[a],
                    }).then((res) => {
                        let fileNames = res.config.params.fileName;
                        let fileData = fileNames.split(".")[1];
                        let blob = new Blob([res.data],{type: 'image/'+ fileData})
                        let url = window.URL.createObjectURL(blob);
                        this.$set(row, 'avatar', url)
                    }).catch(e=> {
                        //console.log('图片展示错误');
                    })
                }
            }
        },
        // 飞行记录
        flightRecords() {
            flightRecord('/flylog/page').then(res => {
                if (res.data.state === 'success') {
                    //console.log('res==飞行记录==>',res);
                    this.tableData = res.data.data
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        // 违规抓拍统计
        captureStatistics() {
            violationCaptureStatistics('/dptj07/wgzptj').then(res => {
                if (res.data.state === 'success') {
                    //console.log('违规抓拍统计===>',res);
                    // 用x轴
                    let a = []
                    // 用于违建抓拍
                    let b = []
                    // 用于违规堆放
                    let c = []
                    // 用于热源抓拍
                    let d = []
                    res.data.data.forEach(item => {
                        a.push(item.yearMonth)
                    })
                    // 去重
                    a = [...new Set(a)]
                    // 排序
                    a = a.sort()
                    // 遍历
                    a.forEach((item) => {
                        let aaa = res.data.data.filter(i => item === i.yearMonth && i.wglx === '违建抓拍')
                        let bbb = res.data.data.filter(i => item === i.yearMonth && i.wglx === '违规堆放')
                        let ccc = res.data.data.filter(i => item === i.yearMonth && i.wglx === '热源抓拍')
                        b.push(aaa.length > 0 ? aaa[0].num : 0)
                        c.push(bbb.length > 0 ? bbb[0].num : 0)
                        d.push(ccc.length > 0 ? ccc[0].num : 0)
                    })
                    this.initChart(a,b,c,d)
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        // 违规图片
        illegalPictureData() {
            illegalPicture('/dptj07/wglxtj', this.year).then(res => {
                if (res.data.state === 'success') {
                    //console.log('按年度统计违规类型分组统计====>',res);
                    res.data.data.forEach(item => {
                        if(item.wglx === "热源抓拍") {
                            this.leftBoxConMain[2].num = item.num
                        }
                        if(item.wglx === "违规堆放") {
                            this.leftBoxConMain[1].num = item.num
                        }
                        if(item.wglx === "违建违章") {
                            this.leftBoxConMain[0].num = item.num
                        }
                    });
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        illegalPictureLists() {
            illegalPictureList('/dptj07/listImage', this.year, 'wjzp').then(res => {
                if (res.data.state === 'success') {
                    //console.log('res==按类型获取图片列表接口==>', res);
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        // 获取任意时间
        getDay(day) {
            var today = new Date();
            var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
            today.setTime(targetday_milliseconds);
            
            var tYear = today.getFullYear();
            var tMonth = today.getMonth();
            var tDate = today.getDate();
            tMonth = this.doHandleMonth(tMonth + 1);
            tDate = this.doHandleMonth(tDate);
            return tYear + "/" + tMonth + "/" + tDate;
        },
        doHandleMonth(month) {
            var m = month;
            if (month.toString().length == 1) {
                m = "0" + month;
            }
            return m;
        },
        // 获取星期
        getCurrentWeek() {
            const days = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
            const date = new Date();
            const index = date.getDay(); // 0-6,0 表示星期日
            this.week = days[index];
        },
        // 天气
        requestLookup() {
            this.$axios({
                method: "GET",
                url: "https://geoapi.qweather.com/v2/city/lookup",
                params: { adm: "无锡市", location: "宜兴市", key: this.weatherKey },
            }).then((res) => {
                if (res.status == 200) {
                let { location } = res.data;
                this.requestweather7d(location);
                }
            });
        },
        // 实时天气
        requestweather7d(location) {
            this.$axios({
                method: "GET",
                url: "https://devapi.qweather.com/v7/weather/now",
                params: { location: location[0].id, key: this.weatherKey },
            }).then((res) => {
                if (res.status == 200) {
                this.weathers = res.data.now;
                this.text = res.data.now.text;
                this.temp = res.data.now.temp;
                this.windDir = res.data.now.windDir;
                this.windScale = res.data.now.windScale;
                }
            });
        },
        toIndex(){
          this.$router.push(window.localStorage.getItem('screenRouter'));
        },
        toPath() {
            //console.log('window.localStorage===>', window.localStorage);
            // window.localStorage.setItem("path", "重点人群,/KeyPopulation");
            // this.$router.push("/KeyPopulation");
        },
        tableRowClassName({row, rowIndex}) {
            if (rowIndex % 2) {
                return 'w-row';
            } else {
                return 'success-row';
            }
        },
        initChart(a,b,c,d) {
            const myChart = echarts.init(document.getElementById('myChart'));
            option.xAxis[0].data = a
            option.series[0].data = b
            option.series[1].data = c
            option.series[2].data = d
            myChart.setOption(option)
        }
    }
}
</script>

<style>
.el-popper {
    background-color: rgba(64, 234, 231, .5);
}
.el-popper .popper__arrow::after {
    border-bottom-color: rgba(64, 234, 231, .5);
}
.el-select {
  display: flex; /* 使用flex布局 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  position: relative; /* 为了绝对定位图标 */
  /* box-shadow: 0px 0px 15px #01dedb inset; */
}
.el-input .el-input__inner {
    height: 30px;
    line-height: 30px;
    background-color: transparent;
    /* color: #fff; */
}
.el-select .el-input__suffix-inner {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.el-select .el-input .el-select__caret {
    color: #fff;
}
.el-table__body {
    position: relative;
    top: 0;
}
.el-table__body .el-table__row {
    color: #fff !important;
}
.el-table__body .el-table__row:hover {
    color: #000 !important;
}
.el-table .el-table__body td, .el-table .el-table__header th {
    border-color: transparent;
}
.el-table tr{
    background-color: transparent !important;
}
.tableStyle {
    background-color: transparent;
}
.el-table .success-row {
    background: linear-gradient(to right, rgba(34, 99, 101, .3), rgba(34, 99, 101, .8), rgba(34, 99, 101, .3))
    /* background: linear-gradient(to right, rgba(179, 243, 247, .2), rgba(179, 243, 247, .8), rgba(179, 243, 247, .2)); */
}
.el-table .w-row {
    background-color: transparent;
}
</style>
<style scoped lang="less">
/deep/.el-input .el-input__inner {
    color: #fff;
}
.tableStyle /deep/ .el-table__header th {
    background-color: transparent;
  color:#fff;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    color: #fff;
}
#ruralScreen {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #000;
}
.header{
    width: 100%;
    height: 96px;
    position: relative;
    background: url('~@/assets/wrj/top.png') no-repeat center;
    background-size: 100% 100%;
    text-align: center;
    overflow: hidden;
    z-index: 9;
        p{
        font-size: 40px;
        background: linear-gradient(to bottom, #fff 50%, #B8F9FD 50%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: 0.1%;
        font-family: 'Title';
        letter-spacing: 4px;
        /*font-style: italic;*/
    }
    .date{
        position: absolute;
        top: 34%;
        left: 17%;
        font-size: 14px;
        width: 170px;
        span{
            margin-left: 10px;
            float: right;
        }
    }
    .weather{
        position: absolute;
        top: 34%;
        right: 17.3%;
        font-size: 14px;
        width: 170px;
        span{
            margin-right: 10px;
            float: left;
        }
    }
    .homepage{
        position: absolute;
        top: 34%;
        left: 3%;
        font-size: 16px;
        cursor: pointer;
        font-family: 'mokuaiTitile';
        background: linear-gradient(to bottom, #fff 50%, #B8F9FD 50%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
#myMap{
    width: 100%;
    height: 100%;

}
.mainBox{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
}
.translations{
  transform: translateX(-100%);
}
.translations2{
  transform: translateX(100%);
}
.lefts{
    width: 38%;
    height: 100%;
    background: url("~@/assets/wrj/lefts.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    transition: transform 1s;
}
.rights{
    // color: red;
    width: 38%;
    height: 100%;
    background: url("~@/assets/wrj/right.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    transition: transform 1s;
}
.bottoms {
    position: absolute;
    bottom: 2vh;
    width: 100%;
    height: 26vh;
    display: flex;
    align-items: center;
  justify-content: space-around;
    .bottoms-one {
        margin-left: 2%;
        flex: 1;
        height: 100%;
        width:40%;
        .boxTitile{
            width: 100%;
            height: 45px;
            background: url("~@/assets/index/titles.png") no-repeat;
            background-size: 100% 100%;
            font-size: 22px;
            font-family: 'mokuaiTitile';
            font-weight: bold;
            line-height: 45px;
            font-style: italic;
            span{
                width: 85%;
                display: inline-block;
                margin-left: 6%;
                background: linear-gradient(to bottom, #fff 40%, #97fcff 60%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        .netWorksBox {
            overflow: hidden;
            // overflow-y: auto;
        }
    }
    .bottoms-two {
        width: 25%;
        height: 100%;
        .boxTitile{
            width: 100%;
            height: 45px;
            background: url("~@/assets/wrj/titles.png") no-repeat;
            background-size: 100% 100%;
            font-size: 22px;
            font-family: 'mokuaiTitile';
            font-weight: bold;
            line-height: 45px;
            font-style: italic;
            span{
                width: 85%;
                display: inline-block;
                margin-left: 6%;
                background: linear-gradient(to bottom, #fff 40%, #97fcff 60%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        .netWorksBox {
          height: 100%;

          .aaaaa {
            width: 100%;
                animation: up 10s linear infinite;
            }
            .aaaaa:hover {
                animation-play-state: paused;
            }
            overflow: hidden;
            // overflow-y: auto;
            .netWorksBox-main {
                position: relative;
                margin-bottom: 20px;
                display: flex;
                img {
                    max-width: 145px;
                    max-height: 95px;
                }
            }
            .netWorksBox-main::after {
                position: absolute; /*绝对定位*/
                bottom: -8px;
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background-color: #fff;
            }
            .netWorksBox-main>div {
                width: 100%;
                margin-left: 10px;
                flex: 1;
                color: #fff;
              font-size:14px;
            }
            .netWorksBox-main>div>.main>span:nth-child(1) {
                margin-right: 10px;
            }
            .netWorksBox-main>div>.main {
                margin-bottom: 4px;
            }
            .netWorksBox-main>div>.main span {
                color: #fff;
            }
            .netWorksBox-main>div>.main4 {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .netWorksBox-main>div>.main3>span:nth-child(2) {
                color: #01dedb !important;
            }
        }
    }
    .bottoms-three {
        margin-right: 2%;
        width: 25.5%;
        height: 100%;
        .boxTitile{
            width: 100%;
            height: 45px;
            background: url("~@/assets/wrj/titles.png") no-repeat;
            background-size: 100% 100%;
            font-size: 22px;
            font-family: 'mokuaiTitile';
            font-weight: bold;
            line-height: 45px;
            font-style: italic;
            span{
                width: 85%;
                display: inline-block;
                margin-left: 6%;
                background: linear-gradient(to bottom, #fff 40%, #97fcff 60%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        .netWorksBox {
            display: flex;
            .swiper {
                width: 300px;
                height: 190px;
                // background-color: red;
            }
            .contant {
                flex: 1;
                margin-left: 10px;
                .leftBoxConMain {
                    margin-bottom: 20px;
                    // width: 220px;
                    height: 50px;
                    display: flex;
                    // border: 1px solid;
                    // border-image: linear-gradient(to right, white, rgba(255, 255, 255, 0)) 10 10;
                    .leftBoxConMain1 {
                        width: 35%;
                        // border-right: 1px solid ;
                        // border-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0)) 0 30;
                        // background: linear-gradient(to bottom right, #20303f 0%, #174c5d 100%);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            display: inline-block;
                            width: 40px;
                            height: 40px;
                        }
                    }
                    .leftBoxs-main {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background: rgba(15, 70, 77, .3);
                        .num {
                            font-size: 20px;
                            color: #01dedb;
                        }
                        .name {
                            font-size: 14px;
                            width: 90px;
                            text-align: center;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
.leftBoxs{
    width: 29%;
    height: 800px;
    margin-top: 11%;
    margin-left: 6%;
    pointer-events: all;
    .leftBoxConMain {
        margin-bottom: 20px;
        width: 220px;
        height: 80px;
        display: flex;
        // border: 1px solid;
        // border-image: linear-gradient(to right, white, rgba(255, 255, 255, 0)) 20 20;
        .leftBoxConMain1 {
            width: 35%;
            // border-right: 1px solid ;
            // border-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0)) 0 30;
            // background: linear-gradient(to bottom right, #20303f 0%, #174c5d 100%);
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                display: inline-block;
                width: 50px;
                height: 50px;
                margin: 15px;
            }
        }
        .leftBoxs-main {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: rgba(15, 70, 77, .3);
            .num {
                font-size: 20px;
                color: #01dedb;
            }
            .name {
                font-size: 14px;
                width: 90px;
                text-align: justify;
                text-align-last: justify;
                color: #fff;
            }
        }
    }
    .mapBottom {
        display: flex;
      align-items: flex-start;
      flex-direction: column;
        .btn {
            width: 40%;
            background: linear-gradient(to right, rgba(34, 99, 101, .3), rgba(34, 99, 101, .8), rgba(34, 99, 101, .3));
            border-radius: 8px;
          height:30px;
          line-height: 30px;
            border: 1px solid #01dedb;
          margin:5px 0;
        }

    }
}
.rightBoxs{
    width: 67%;
    height: 60vh;
    margin-top: 11%;
    margin-right: 6%;
    pointer-events: all;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
}
.boxTitile{
    width: 100%;
    height: 45px;
    background: url("~@/assets/wrj/titles.png") no-repeat;
    background-size: 100% 100%;
    font-size: 22px;
    font-family: 'mokuaiTitile';
    font-weight: bold;
    line-height: 45px;
    font-style: italic;
    span{
        width: 85%;
        display: inline-block;
        margin-left: 6%;
        background: linear-gradient(to bottom, #fff 40%, #97fcff 60%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
.actives{
    color: #999;
}
.distribution{
    width: 100%;
    height: 36vh;
    display: flex;
    flex-direction: column;
}
.distributionBox{
    width: 100%;
    margin-top: 10px;
    overflow: hidden;
    cursor: pointer;
    flex: 1;
    display: flex;
    flex-direction: column;
    .distributionBoxMain {
        display: flex;
        width: 100%;
      justify-content: space-between;
        .distributionBoxMain-left {
            width: 35%;
            margin: 0px 10px;
            img {
                width: 100%;
            }
        }
        .distributionBoxMain-right {
          width:65%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          padding: 10px;
          .right-top .sel{
            width:100%;
            font-size:16px;
            box-shadow:inset 0 0 10px #00fff3;
          }
            .right-top>span {
                color: #fff;
              font-size: 16px;
              line-height: 30px;

            }
            .right-top>span:nth-child(1) {
                width: 80px;
            }
            .right-top>span:nth-child(2) {
                color: #01dedb;
            }
            .right-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .sheBei {
                border-bottom: 1px solid rgb(154, 155, 157);
            }
        }
    }
    .distributionBoxTitle {
        margin-left: 10px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .distributionBoxTitle-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .one {
                font-size: 14px;
                width: 30%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .one>div:nth-child(1) {
                color:#fff;
            }
            .one>div:nth-child(2) {
                font-size: 16px;
                color: #01dedb;
                width: 120px;
                height: 50px;
                text-align: center;
                line-height: 50px;
            }
        }
        .distributionBoxTitle-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .two {
                font-size: 14px;
                width: 140px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .two>div:nth-child(1) {
                color: #fff;
            }
            .two>div:nth-child(2) {
                font-size: 18px;
                color: #01dedb;
                width: 120px;
                height: 35px;
                text-align: center;
                line-height: 35px;
            }
        }
    }
}
.minimumBottom{
    font-family: 'mokuaiTitile';
    font-size: 14px;
    line-height: 40px;
}
.networks{
    // background: red;
    width: 100%;
    height: 24vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .netWorksBox {
        flex: 1;
      height: 265px;

    }
}
.netWorksBox{
    width: 96%;
    margin: 5px auto 0;
}
.networksItem{
    width: 99%;
    height: 265px;
    margin: 0 auto;
    overflow: auto;
    background-color: rgba(28, 123, 113, .2);
}
.networksItem::-webkit-scrollbar{
    display: none;
}
.networksUl{
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #1C7B71;
    display: flex;
    text-align: center;
    line-height: 40px;
    font-family: 'mokuaiXi';
    font-size: 14px;
  cursor: pointer;
    li{
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.interview{
    width: 100%;
    height: 265px;
}
.interviewBox{
    width: 92%;
    height: 200px;
    margin: 5px auto 0;
    position: relative;
}
@keyframes up {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-70%);
  }
}
</style>