import * as echarts from 'echarts';
let color = ["#01dedb", "#00CA69", "#ffd200"];
let xAxisData = ["01", "02", "03", "04", "05", "06", "07", "08"];
// 违建抓拍
let yAxisData1 = [100, 138, 350, 173, 180, 150, 180, 230];
// 违规堆放
let yAxisData2 = [233, 233, 200, 180, 199, 233, 210, 180];
// 热源抓拍
let yAxisData3 = [23, 23, 20, 10, 19, 23, 20, 10];
const hexToRgba = (hex, opacity) => {
let rgbaColor = "";
let reg = /^#[\da-f]{6}$/i;
if (reg.test(hex)) {
    rgbaColor = `rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt(
    "0x" + hex.slice(3, 5)
    )},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
}
return rgbaColor;
};

let option = {
    backgroundColor: 'transparent',
    color: color,
    legend: {
        top: 0,
    },
    tooltip: {
        trigger: "axis",
        formatter: function (params) {
        let html = "";
        params.forEach((v) => {
            html += `<div style="color: #ffffff;font-size: 14px;line-height: 24px">
                    <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color[v.componentIndex]};"></span>
                    ${v.seriesName}${v.name}  
                    <span style="color:${color[v.componentIndex]};font-weight:700;font-size: 18px;margin-left:5px">${v.value}</span> 次`;
        });
        return html;
        },
        extraCssText:
        "background: #000; border-radius: 5px;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #fff;",
        axisPointer: {
        type: "shadow",
        shadowStyle: {
            // color: "#ffffff",
            shadowColor: "rgba(225,225,225,1)",
            shadowBlur: 5,
        },
        },
    },
    grid: {
        left: "2%",
        right: "2%",
        top: "20%",
        bottom: "0",
        containLabel: true,
        },
    xAxis: [
        {
        type: "category",
        boundaryGap: false,
        axisLabel: {
            // formatter: "2020-{value}",
            textStyle: {
            color: "#ffffff",
            },
        },
        axisLine: {
            lineStyle: {
            color: "#D9D9D9",
            },
        },
        data: xAxisData,
        },
    ],
    yAxis: [
        {
        type: "value",
        name: "（次）",
        axisLabel: {
            textStyle: {
            color: "#ffffff",
            },
        },
        nameTextStyle: {
            color: "#ffffff",
            fontSize: 12,
            lineHeight: 40,
        },
        // 分割线
        splitLine: {
            lineStyle: {
            type: "dashed",
            color: "#E9E9E9",
            },
        },
        axisLine: {
            show: false,
        },
        axisTick: {
            show: false,
        },
        },
    ],
    series: [
        // 违建抓拍
        {
        // name: "2018",
        name: "违建抓拍",
        type: "line",
        smooth: true,
        symbolSize: 8,
        zlevel: 3,
        lineStyle: {
            normal: {
            color: color[0],
            shadowBlur: 3,
            shadowColor: hexToRgba(color[0], 0.5),
            shadowOffsetY: 8,
            },
        },
        symbol: "circle", //数据交叉点样式
        areaStyle: {
            normal: {
            color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                {
                    offset: 0,
                    color: hexToRgba(color[0], 0.3),
                },
                {
                    offset: 1,
                    color: hexToRgba(color[0], 0.1),
                },
                ],
                false
            ),
            shadowColor: hexToRgba(color[0], 0.1),
            shadowBlur: 10,
            },
        },
        data: yAxisData1,
        },
        // 违规堆放
        {
        name: "违规堆放",
        type: "line",
        smooth: true,
        symbolSize: 8,
        zlevel: 3,
        lineStyle: {
            normal: {
            color: color[1],
            shadowBlur: 3,
            shadowColor: hexToRgba(color[1], 0.5),
            shadowOffsetY: 8,
            },
        },
        symbol: "circle", //数据交叉点样式 (实心点)
        areaStyle: {
            normal: {
            color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                {
                    offset: 0,
                    color: hexToRgba(color[1], 0.3),
                },
                {
                    offset: 1,
                    color: hexToRgba(color[1], 0.1),
                },
                ],
                false
            ),
            shadowColor: hexToRgba(color[1], 0.1),
            shadowBlur: 10,
            },
        },
        data: yAxisData2,
        },
        // 热源抓拍
        {
        name: "热源抓拍",
        type: "line",
        smooth: true,
        symbolSize: 8,
        zlevel: 3,
        lineStyle: {
            normal: {
            color: color[2],
            shadowBlur: 3,
            shadowColor: hexToRgba(color[2], 0.5),
            shadowOffsetY: 8,
            },
        },
        symbol: "circle", //数据交叉点样式 (实心点)
        areaStyle: {
            normal: {
            color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                {
                    offset: 0,
                    color: hexToRgba(color[2], 0.3),
                },
                {
                    offset: 1,
                    color: hexToRgba(color[2], 0.1),
                },
                ],
                false
            ),
            shadowColor: hexToRgba(color[2], 0.1),
            shadowBlur: 10,
            },
        },
        data: yAxisData3,
        },
    ],
};

export {
    option
}
  